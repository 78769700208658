@import 'styles/colors';

@topNavColor: #fff;
@topNavTextColor: #333;
@leftNavColor: darken(color(@customerPrimary), 12.5%);
@leftNavSubColor: darken(color(@customerPrimary), 10%);
@userInfoBackgroundColor: @customerPrimary;
@navigationSubColor: darken(color(@customerPrimary), 7.5%);

.content-wrapper {
    background-color: #f2f2f2;
}

.skin-blue .main-header .logo,
.skin-blue .main-header .logo:hover {
    background-color: @topNavColor;
    color: @topNavTextColor;
}

.skin-blue .main-header .navbar {
    background-color: @topNavColor;
}

.skin-blue .main-header .navbar .nav > li > a,
.navbar-custom-menu .navbar-nav.nav-header-button,
.navbar-nav.submit-help {
    color: @topNavTextColor !important;
}

.skin-blue .left-side,
.skin-blue .main-sidebar,
.skin-blue .wrapper {
    background-color: @leftNavColor;
}

.skin-blue .sidebar-menu > li.active > app-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > app-nav-menu-item > a {
    background-color: darken(color(@leftNavColor), 5%);
}

.skin-blue .sidebar-menu > li > .treeview-menu {
    background-color: @leftNavSubColor;
}

/* "NAVIGATION" menu item */
.skin-blue .sidebar-menu > li.header {
    background-color: @navigationSubColor;
}

/* hide text name */
.main-header .logo .logo-lg > b {
    display: none;
}

.main-header .logo {
    padding-left: 24px;
}

.main-header .logo .logo-lg > img {
    max-height: 45px;
    margin-top: 1px;
}

.skin-blue .main-header li.user-header {
    background-color: @leftNavColor;
}

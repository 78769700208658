@import 'styles/colors';
@import 'styles/nav';

@font-face {
    font-family: 'Roboto Condensed', sans-serif;
    src: url('./assets/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf') format('truetype'),
        url('./assets/fonts/Roboto_Condensed/RobotoCondensed-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf') format('truetype'),
        url('./assets/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf') format('truetype'),
        url('./assets/fonts/Roboto_Condensed/RobotoCondensed-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-MediumItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Montserrat/static/Montserrat-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Nunito', sans-serif;
    src: url('./assets/fonts/Nunito/static/Nunito-ExtraLight.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-Light.ttf') format('truetype'),
        url(./assets/fonts/Nunito/static/Nunito-Regular.ttf) format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-Medium.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-SemiBold.ttf') format('truetype'),
        url(./assets/fonts/Nunito/static/Nunito-Bold.ttf) format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-ExtraBold.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-Black.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-ExtraLightItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-Italic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-MediumItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-SemiBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-ExtraBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/Nunito/static/Nunito-BlackItalic.ttf') format('truetype');
}

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

.archived-row td {
    background-color: beige !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited,
.btn-success {
    background-color: #2fb8e1;
    border-color: #2fb8e1;
}

.btn-primary.active,
.btn-primary.active:hover,
.btn-primary.focus.active,
.btn-primary.focus.active:hover,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:hover,
.btn-success:active:focus,
.btn-success:hover {
    background-color: #1384a7;
    border-color: #1384a7;
    outline: 0;
}

.btn.btn-fab-lg,
.btn.btn-fab-md,
.btn.btn-fab-spin-lg {
    background-color: #a5c029;
    border-color: #a5c029;
    border-radius: 100%;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 12%);
    z-index: 2;
    transform: translateZ(1px);
    -webkit-transform: translateZ(1px);
    -moz-transform: translateZ(1px);
    -o-transform: translateZ(1px);
    transition: transform 0.2s;
}

.btn.btn-fab-lg:hover,
.btn.btn-fab-md:hover,
.btn.btn-fab-spin-lg:hover {
    background-color: #758b13;
}

.signature-pad-modal > .swal2-popup {
    width: 560px;
}

.skin-blue .sidebar-menu > li.header {
    color: white;
}

.table-striped > tbody > tr.tr-proposal-expired:nth-child(odd) td {
    background-color: rgba(244, 140, 140, 0.986) !important;
}

.table-striped > tbody > tr.tr-proposal-expired:nth-child(even) td {
    background-color: rgba(244, 140, 140, 0.986) !important;
}

.table-striped > tbody > tr.tr-customer-archived:nth-child(odd) td {
    background-color: rgba(244, 140, 140, 0.986) !important;
}

.table-striped > tbody > tr.tr-customer-archived:nth-child(even) td {
    background-color: rgba(244, 140, 140, 0.986) !important;
}